<template>
  <a-row :style="{
    'margin-bottom': margin + 'px'
  }">
    <a-col :span="labelCol" v-if="title">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="title ? valueCol : 24">
      <a-select
          v-model:value="inputValue"
          style="width: 100%"
          :loading="loading"
          :options="options"
          @change="onChange"
          :disabled="disabled"
          :placeholder="'请选择' + title"
        ></a-select>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: ''
    },

    showTitle: {
      type: Boolean,
      default: true
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    margin: {
      type: Number,
      default: 15
    },

    options: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('ok', e)
      emit('update:value', e)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>
<style lang="less">
@import url('../form/form.less');
</style>