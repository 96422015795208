<template>
  <a-row class="search-params-margin">
    <a-col :span="labelCol" v-if="title">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="title ? valueCol : 24">
      <a-input v-model:value="inputValue" @change="onChange" allowClear :disabled="disabled" :type="type"
        :placeholder="placeholder ? placeholder : ('请输入' + title)" />
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: String
  },

  title: {
    type: String,
    default: ''
  },

  placeholder: {
    type: String,
    default: ''
  },

  labelCol: {
    type: Number,
    default: 4
  },

  valueCol: {
    type: Number,
    default: 16
  },

  disabled: {
    type: Boolean,
    default: false
  },

  type: {
    type: String,
    default: 'text'
  },
  showTitle: {
    type: Boolean,
    default: true
  }
})

// eslint-disable-next-line no-undef
const emits = defineEmits(['ok', 'update:value'])

const inputValue = ref(props.value)
watch(props, e => {
  inputValue.value = e.value
})

const onChange = e => {
  emits('ok', e.target.value)
  emits('update:value', e.target.value)
}
</script>
<style lang="less">
@import url('../form/form.less');
</style>
