import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "device-layout" }
const _hoisted_2 = { class: "left-menus" }
const _hoisted_3 = { class: "menu-list" }
const _hoisted_4 = { class: "work-area" }
const _hoisted_5 = { class: "body-view" }

import {SettingOutlined} from '@ant-design/icons-vue'
import { nextTick, onMounted, ref } from 'vue'
import { useAppStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'SysLayout',
  setup(__props) {

const appStore = useAppStore()

const deviceContentRef = ref()


onMounted(() => {
  nextTick(() => {
    appStore.setDeviceContentHeight(deviceContentRef.value.offsetHeight)
    appStore.setDeviceContentWidth(deviceContentRef.value.offsetWidth)
  })
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/iot/sys/setting",
          class: "menu-item"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SettingOutlined)),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "menu-title" }, "开发设置", -1))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "device-content",
          ref_key: "deviceContentRef",
          ref: deviceContentRef
        }, [
          _createVNode(_component_router_view)
        ], 512)
      ])
    ])
  ]))
}
}

})