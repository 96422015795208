import defaultConfig from "@/configs/default.config";
import axios from "axios"
import { notification } from 'ant-design-vue'
import router from '../router'
import QueryString from "qs"
import storage from "./storage"


const http = axios.create({
  baseURL: defaultConfig.host,
  timeout: 60000
})

// 异常拦截处理器
const errorHandler = (error: any) => {
  console.log(error)
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 500) {
      notification.error({
        message: '服务器错误',
        description: data.title
      })
    }
    if (error.response.status === 404) {
      notification.error({
        message: '服务器错误',
        description: '接口不存在'
      })
    }
    if (error.response.status === 403) {
      notification.error({
        message: '请求被拒绝',
        description: data.title
      })
    }
    if (error.response.status === 400) {
      notification.error({
        message: '警告',
        description: data.title
      })
    }
    if (error.response.status === 401) {
      notification.error({
        message: '验证失败',
        description: '当前用户状态验证失败，请重新登录'
      })

      storage.remove('BEARER_TOKEN')
      router.push({ name: 'iot-login' })
    }
  }
  return Promise.reject(error.response)
}

http.interceptors.request.use(config => {
  const token = storage.get<string>('BEARER_TOKEN')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }

  config.paramsSerializer = params => {
    return QueryString.stringify(params, { arrayFormat: 'repeat' })
  }

  return config
}, errorHandler)

http.interceptors.response.use(response => {
  if (response.status === 204) {
    return null
  }

  return response.data
}, errorHandler)



export default http;