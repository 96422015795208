import { AdClass, NewsClass } from "@/apis"
import { AdDto, NewsDto, QueryAdArgs, QueryNewsArgs, ResponseDataType } from "@/models/types"
import { defineStore } from "pinia"

const ad = new AdClass()
const news = new NewsClass()
/**
 * ucenter接口调用，包括新闻、广告等
 */
export const useUcenterStore = defineStore('ucenter', {
  state: () => {
    return {}
  },

  actions: {
    getAds(params: QueryAdArgs){
      return new Promise<AdDto[]>((resolve, reject) => {
        ad.gets(params).then((resp: ResponseDataType<AdDto>) => {
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    getNews(params: QueryNewsArgs){
      return new Promise<NewsDto[]>((resolve, reject) => {
        news.gets(params).then((resp: ResponseDataType<NewsDto>) => {
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
})