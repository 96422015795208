<template>
  <a-row class="search-params-margin">
    <a-col :span="labelCol" v-if="title">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="title ? valueCol : 24">
      <a-cascader
        v-model:value="inputValue"
        :options="options"
        :show-search="{ filter }"
        change-on-select
        expandTrigger="hover"
        :placeholder="'选择' + title"
        @change="onChange"
      />
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Array],
      default: () => []
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },
    options: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e, selectedOptions) => {
      emit('ok', {selectArr: e, selectedOptions})
      emit('update:value', e)
    }

    const filter = (inputValue, path) => {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
    }

    return {
      inputValue,
      onChange,
      filter
    }
  }
})
</script>
