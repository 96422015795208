import { ColumnType, LngLatFieldsType } from "@/models/types"
import storage from "@/utils/storage"
import { defineStore } from "pinia"

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      host: '' as string,
      company: '' as string,
      version: '' as string,
      projectTitle: '' as string,
      rcToken: '' as string,
      checkAuth: false as boolean,

      contactName: '' as string,
      contactInfo: '' as string,

      deviceContentHeight: 0,
      deviceContentWidth: 0,

      userDataTplMapLngLatFields: [] as LngLatFieldsType[],

      deviceControlColumns: [] as ColumnType[],
      deviceColumns: [
        {
          title: '设备名称',
          dataIndex: 'title',
          width: 160,
        },
        {
          title: '序列号',
          dataIndex: 'sn',
          width: 160,
        },
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          width: 200,
        },
        {
          title: '密钥',
          dataIndex: 'secretKey',
          width: 120,
        },
        {
          title: '设备模板',
          dataIndex: 'userDataTpl',
          width: 160,
        },
        {
          title: '是否在线',
          dataIndex: 'isOnline',
          width: 100,
        },
        {
          title: '静默升级',
          dataIndex: 'firmwareUpdateMode',
          width: 100,
        },
        {
          title: '固件版本',
          dataIndex: 'curVersion',
          width: 100,
        },
        {
          title: '最新版本',
          dataIndex: 'newVersion',
          width: 120,
        },
        {
          title: '是否激活',
          dataIndex: 'isActive',
          width: 100,
        },
        {
          title: '负载配置',
          dataIndex: 'hostType',
          width: 100,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: 160,
        },
        {
          title: '最后上线时间',
          dataIndex: 'onlineTime',
          width: 160,
        },
        {
          title: '最后离线时间',
          dataIndex: 'offlineTime',
          width: 160,
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 330,
          fixed: 'right'
        }
      ],
      
      dataTypes: [
        {label: '数值(整数)', value: 0},
        {label: '字符串', value: 1},
        {label: 'BCD编码', value: 2},
        {label: '开关', value: 3},
        {label: '经纬度', value: 4},
        {label: '数值(精度1)', value: 5},
        {label: '数值(精度2)', value: 6},
        {label: '数值(精度3)', value: 7},
        {label: '数值(精度4)', value: 8},
        {label: '数值(精度5)', value: 9},
        {label: '数值(精度6)', value: 10},
        {label: '数值(精度7)', value: 11}
      ],
      onLineOptions: [
        {label: '在线', value: true},
        {label: '离线', value: false}
      ],
      activeOptions: [
        {label: '激活', value: true},
        {label: '停用', value: false}
      ]
    }
  },
  getters: {},
  actions: {
    setDeviceContentHeight(value: number){
      this.deviceContentHeight = value
      console.log('设备工作区高度：',value + 'px')
    },
    setDeviceContentWidth(value: number){
      this.deviceContentWidth = value
      console.log('设备工作区宽度：',value + 'px')
    },

    setDeviceControlColumns(value: ColumnType[]){
      storage.set('DEVICE_COLUMNS', value)
    },

    getDeviceControlColumns(){
      const columns = storage.get<ColumnType[]>('DEVICE_COLUMNS')
      if(columns){
        this.deviceControlColumns = columns
      }else{
        this.deviceControlColumns = this.deviceColumns
      }
    },

    setUserDataTplMapLngLatFields(value: LngLatFieldsType[]){
      storage.set('USER_DATA_TPL_MAP_LNG_LAT_FIELDS', value)
    },

    getUserDataTplMapLngLatFields(){
      const fields = storage.get<LngLatFieldsType[]>('USER_DATA_TPL_MAP_LNG_LAT_FIELDS')
      
      if(fields){
        this.userDataTplMapLngLatFields = fields
      }else{
        this.userDataTplMapLngLatFields = []
      }
    }
  }
})