<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')
const locale = zhCN
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a{
  text-decoration: none;
  color: #1677ff;
}
input{
  box-shadow: 0 0 0px 1000px white inset;
}
.ant-breadcrumb .ant-breadcrumb-separator{
  color: rgba(255, 255, 255, 0.5);
}
.ant-modal-mask,
.ant-message,
.ant-notification,
.ant-cascader-menus,
.ant-picker-dropdown {
  z-index: 99997 !important;
}
.ant-modal-wrap {
  z-index: 99998 !important;
}
.ant-select-dropdown {
  z-index: 100001 !important;
}
:where(.css-dev-only-do-not-override-17yhhjv).ant-form-item .ant-form-item-control-input-content{
  display: flex;
  align-items: flex-start;
}

.line{
  margin-bottom: 10px;
  width: 100%;
  display: flex;

  .msg{
    border-radius: 5px;
    padding: 10px;
    background-color: #f2f2f2;
    max-width: 100%;
    word-break: break-all;
  }
}
</style>
