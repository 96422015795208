<template>
  <a-row class="search-params-margin">
    <a-col :span="labelCol" v-if="title">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="title ? valueCol : 24">
      <a-switch
          v-model:checked="inputValue"
          @change="onChange"
          checked-children="是"
          un-checked-children="否"
        />
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: '标题'
    },

    help: {
      type: String,
      default: ''
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('ok', e)
      emit('update:value', e)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>
