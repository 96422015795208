import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
const pinia = createPinia()
import bootstrap from './core/bootstrap'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import permission from './permission'
import 'animate.css'
import filter from '@/utils/filter'

import EIconFont from './components/IconFont'
import EpModal from './components/EpModal.vue'
import FormInput from './components/form/FormInput.vue'
import FormRadio from './components/form/FormRadio.vue'
import FormSelect from './components/form/FormSelect.vue'
import FormCascader from './components/form/FormCascader.vue'
import FormInputNumber from './components/form/InputNumber.vue'
import FormInfo from './components/form/FormInfo.vue'


import SearchInput from './components/search-form/FormInput.vue'
import SearchSelect from './components/search-form/FormSelect.vue'
import SearchInputNumber from './components/search-form/InputNumber.vue'
import SearchCascader from './components/search-form/FormCascader.vue'
import SearchRadio from './components/search-form/FormRadio.vue'
import SearchSwitch from './components/search-form/FormSwitch.vue'
import SearchTreeSelect from './components/search-form/TreeSelect.vue'
import SearchDateTime from './components/search-form/DateTime.vue'

const app = createApp(App)

app.config.globalProperties.$filter = filter

app.use(pinia)
bootstrap.init()
permission.init()

app.component('icon-font', EIconFont)
app.component('e-modal', EpModal)
app.component('e-input', FormInput)
app.component('e-radio', FormRadio)
app.component('e-info', FormInfo)
app.component('e-select', FormSelect)
app.component('e-cascader', FormCascader)
app.component('e-input-number', FormInputNumber)


app.component('s-input', SearchInput)
app.component('s-select', SearchSelect)
app.component('s-input-number', SearchInputNumber)
app.component('s-cascader', SearchCascader)
app.component('s-radio', SearchRadio)
app.component('s-switch', SearchSwitch)
app.component('s-tree-select', SearchTreeSelect)
app.component('s-date-time', SearchDateTime)

app.use(router)
app.use(Antd)
app.mount('#app')



