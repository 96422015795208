export default {
  host: process.env.VUE_APP_HOST,
  mqtt: process.env.VUE_APP_MQTT_HOST,
  version: process.env.VUE_APP_VERSION,
  check_auth: process.env.VUE_APP_CHECK_AUTH,
  rc_token: process.env.VUE_APP_RC_TOKEN,
  project_title: process.env.VUE_APP_PROJECT_TITLE,
  project_logo: process.env.VUE_APP_PROJECT_LOGO,
  project_logo_white: process.env.VUE_APP_PROJECT_LOGO_WHITE,
  contact_name: process.env.VUE_APP_CONTACT_NAME,
  contact_email: process.env.VUE_APP_CONTACT_EMAIL,
  copyright: process.env.VUE_APP_COPYRIGHT,
  company: process.env.VUE_APP_COMPANY
}