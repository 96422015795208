<template>
  <div class="basic-layout">
    <div class="header">

      <div class="left">        
        <router-link to="/" class="logo">
          <img :src="project_logo_white" alt="">

          {{ project_title }}
        </router-link>

        <a-breadcrumb :routes="routes" separator=">" class="breadcrumb">
          <template #itemRender="{ route, routes }">
            <span v-if="routes.indexOf(route) === routes.length - 1" class="curadd">
              {{ route.title }}
            </span>
            <router-link v-else :to="route.path" class="link">
              {{ route.title }}
            </router-link>
          </template>
        </a-breadcrumb>
      </div>

      <div class="right">

        <div class="userinfo" @mouseenter="() => showModal = true" @mouseleave="onDropLeave">
          <div class="user-name">{{ userInfo?.username }}</div>

          <div class="user-avatar">
            <img :src="userInfo?.avatar" v-if="userInfo?.avatar" alt="" />
            <img src="../assets/avatar.png" v-else alt="">
          </div>
        </div>


      </div>
    </div>
    <div class="body">
      <div class="body-view">
        <router-view></router-view>
      </div>
    </div>


    <div class="userinfo-modal" :class="{
      'show': showModal
    }" @mouseleave="onModalMouseleave" @mouseenter="onModalMouseenter">
      <div class="modal-info">
        <div class="username">
          {{ userInfo?.username }}
        </div>
        <div class="userid">
          KEY: <span class="small">{{ userInfo?.id }}</span>
          <a-tooltip placement="bottom">
            <template #title>复制</template>
            <a href="" class="copy" @click.prevent.stop="onCopy(userInfo?.id)">
              <CopyOutlined />
            </a>
          </a-tooltip>
        </div>
      </div>

      <div class="modal-info">
        <router-link to="" class="link-menu">账号信息</router-link>
        <router-link to="" class="link-menu">实名认证</router-link>
        <router-link to="" class="link-menu">安全设置</router-link>
      </div>

      <a-button block danger @click="onLogout">退出登录</a-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import defaultConfig from '@/configs/default.config'
import { useUserStore } from '@/store'
import { message } from 'ant-design-vue'
import { CopyOutlined } from '@ant-design/icons-vue'
import router from '@/router'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { BreadcrumbRoute } from '@/models/types'


const route = useRoute()

const routes = ref<BreadcrumbRoute[]>([])
const updateRoute = () => {
  const matched = route.matched
  const routeList:BreadcrumbRoute[] = []

  matched.filter(x => x.name !== 'iot-control').map((e) => {
    routeList.push({
      path: e.path,
      title: (e.path === '/' ? '控制台' : e.meta.title) as string
    })
  })

  routes.value = routeList
}

watch(route, () => {
  console.log('路由变化')
  updateRoute()
}, { immediate: true })


const userStore = useUserStore()

const userInfo = userStore.userInfo
const { project_title,project_logo_white } = defaultConfig
const showModal = ref(false)


const onCopy = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
    message.success('已复制到粘贴板')
  } catch (err) {
    message.error('复制失败：' + err)
  }
}

const onLogout = () => {
  userStore.onLogout()
  message.success('退出登录成功')
  router.push({ name: 'iot-login' })
}

const inModal = ref(false)
const onModalMouseleave = () => {
  showModal.value = false
  inModal.value = false
}
const onModalMouseenter = () => {
  inModal.value = true
}
const onDropLeave = () => {
  setTimeout(() => {
    if (!inModal.value) {
      showModal.value = false
      inModal.value = false
    }
  }, 100)
}
</script>

<style lang="less" scoped>
.basic-layout {
  width: 100%;
  height: 100vh;
  background-color: #f2f4f8;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .header {
    height: 50px;
    background-color: #202020;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 9999;

    .left {
      height: 32px;
      display: flex;
      align-items: flex-end;
      padding-left: 10px;

      .logo {
        display: flex;
        align-items: center;
        font-size: 16px;
        text-decoration: none;
        color: #fff;

        img {
          display: block;
          height: 32px;
        }
      }
      .breadcrumb{
        padding-bottom: 3px;
        color: #fff;
        padding-left: 20px;
        margin-left: 16px;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          border-left: 1px solid rgba(255, 255, 255, 0.4);
          height: 18px;
        }
        .link,.curadd{
        font-size: 14px;
        color: #fff;
        
      }
      }

      
    }

    .right {
      padding-right: 10px;
      position: relative;
      height: 50px;
      display: flex;
      align-items: center;

      .userinfo {
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .user-name {
          color: #e2e0e0;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: none;
          font-size: 12px;
          padding-right: 10px;
        }

        .user-avatar {
          border-radius: 50%;
          width: 38px;
          height: 38px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #1677ff;

          img {
            display: block;
            height: 22px;
          }
        }
      }


    }


  }

  .body {
    flex: 1;
    overflow: hidden;

    .body-view {
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      overflow: auto;
    }
  }

  .userinfo-modal {
    position: absolute;
    top: -500px;
    right: 0;
    width: 400px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    transition: top .2s ease;
    z-index: 999;

    &.show {
      top: 50px;
    }

    .modal-info {
      border-bottom: 1px solid #f1f1f1;
      padding: 20px 0;

      .username {
        padding: 0 20px;
        font-size: 16px;
        line-height: 32px;
        .copy {
          font-size: 14px;
        }
      }

      .userid{
        padding: 0 20px;
        font-size: 12px;
        .small{
          font-size: 12px;
        }
        .copy {
          font-size: 12px;
          margin-left: 10px;
        }
      }

      .link-menu {
        display: block;
        font-size: 12px;
        line-height: 30px;
        color: #666;
        padding: 5px 20px;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
}
</style>