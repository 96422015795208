<template>
  <div class="public-layout">
    <div class="header">
      <a href="/" class="logo">
        <img :src="project_logo" alt="">

        {{ project_title }}
      </a>
      <a-divider type="vertical" />

      <h1>{{ $route.meta.title }}</h1>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="copyright">{{ copyright }} {{ company }} 版权所有</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import defaultConfig from '@/configs/default.config'

const {project_title, company,project_logo,copyright} = defaultConfig
</script>

<style lang="less" scoped>
.public-layout{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url('../assets/login-bg.jpg') no-repeat top center;
  background-size: 100% auto;
  .header{
    height: 50px;
    padding: 9px;
    display: flex;
    font-size: 18px;
    align-items: center;
    color: #000;
    .logo{
      display: flex;
      align-items: center;
      font-size: 16px;
      text-decoration: none;
      color: #333;
      img{
        display: block;
        height: 32px;
      }
    }
    h1{
      font-size: 18px;
      padding: 0;
      margin: 0;
    }
  }
  .body{
    flex: 1;
  }
  .footer{
    height: 60px;
    .copyright{
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>