/**
 * 初始化一些数据，保证页面刷新时store中的数据都是有赋值的
 */

import { useAppStore, useUserStore } from '@/store'
import defaultConfig from '@/configs/default.config'
import storage from '@/utils/storage'

export default {
  init() {
    console.log('载入默认配置')
    const appStore = useAppStore()
    const userStore = useUserStore()

    appStore.$patch({
      host: defaultConfig.host,
      company: defaultConfig.company,
      version: defaultConfig.version,
      projectTitle: defaultConfig.project_title,
      rcToken: defaultConfig.rc_token,
      checkAuth: defaultConfig.check_auth
    })

    userStore.setToken(storage.get<string>('BEARER_TOKEN') as string)
  }
}