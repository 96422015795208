export default {
  get<T>(key: string): T | null{
    const str = localStorage.getItem(key)
    if(str){
      return JSON.parse(str)
    }

    return null;
  },

  set<T>(key:string, value: T){
    localStorage.setItem(key, JSON.stringify(value))
  },

  remove(key: string){
    localStorage.removeItem(key)
  },

  clearAll(){
    localStorage.clear()
  }

}